@use "../../../style/variables";

.burger-menu {
  &.MuiDrawer-root,
  .MuiDrawer-paper {
    width: variables.$burger-menu-width;
    top: variables.$header-height + 1;
  }

  &__header,
  .MuiDrawer-paper {
    background-color: #fff;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
